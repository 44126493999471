<template>
  <nav class="navbar navbar-expand-md navbar-white">
    <div class="container">
      <div class="navbar-brand">Adam L Miller</div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarPrimary" aria-controls="navbarPrimary" aria-expanded="false" aria-label="Toggle Navigation">
        <i class="fas fa-fw fa-bars"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarPrimary">
        <ul class="navbar-nav ml-auto mb-2 mb-md-0">
          <li class="nav-item"><router-link class="nav-link" aria-current="page" to="/">Home</router-link></li>
          <li class="nav-item"><router-link class="nav-link" aria-current="page" to="/experience">Experience</router-link></li>
          <li class="nav-item"><router-link class="nav-link" aria-current="page" to="/portfolio">Portfolio</router-link></li>
          <li class="nav-item"><router-link class="nav-link" aria-current="page" to="/skills">Skills</router-link></li>
          <li class="nav-item"><router-link class="nav-link" aria-current="page" to="/about">About</router-link></li>
          <li class="nav-item"><router-link class="nav-link nav-link-button" aria-current="page" to="/contact">Get in touch</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    background: {
      required: false
    }
  },
  data: () => ({
    showNavbar: true,
    lastScrollPosition: 0
  }),
  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop

      if (currentScrollPosition < 0) {
        return
      }

      this.showNavbar = currentScrollPosition < this.lastScrollPosition
      this.lastScrollPosition = currentScrollPosition
    }
  }
}
</script>
<template>
  <section>
    <div class="header pb-5">
      <app-navigation />

      <br />

      <div class="header-title">
        <div class="row">
          <div class="col-12 text-center">
            <h1>Get in Touch</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div class="header-breadcrumb">
              <router-link to="/"><i class="fa fa-fw fa-home"></i></router-link> / Get in Touch
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main pt-5">
      <div class="container">
        <h3 class="blue pb-3">Need some work done? Please, feel free to reach out!</h3>
        <p>Are you looking for a freelancer with my skill set? Perhaps you have a permanent full-time position to full? Please, feel free to reach out to me. I am always interested in hearing about opportunities whether freelance or permanent full-time.</p>

        <div class="row pt-2">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
            <i class="fa fa-mobile-alt fa-2x circle-icon" />

            <h5 class="pb-2">Call</h5>

            602.334.3750
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
            <i class="far fa-paper-plane fa-2x circle-icon" />

            <h5 class="pb-2">Email</h5>

            me [at] adamlmiller.com
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 text-center">
            <i class="far fa-comments fa-2x circle-icon" />

            <h5 class="pb-2">Social</h5>

            <router-link to="" target="_blank">LinkedIn</router-link><br />
            <router-link to="" target="_blank">Github</router-link>
          </div>
        </div>

        <div class="form">
          <h3 class="text-center pb-5">Contact Form</h3>

          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="row pb-5">
                <div class="col-12">
                  <input class="form-control" type="text" placeholder="Your Name">
                </div>
              </div>
              <div class="row pb-5">
                <div class="col-12">
                  <input class="form-control" type="text" placeholder="Subject">
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <div class="row pb-5">
                <div class="col-12">
                  <input class="form-control" type="text" placeholder="Your Email">
                </div>
              </div>
              <div class="row pb-5">
                <div class="col-12">
                  <input class="form-control" type="text" placeholder="Your Telephone">
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-5">
            <div class="col-12">
              <textarea class="form-control" rows="10" placeholder="Drop us a line..."></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-center">
              <button type="button" class="btn btn-light"><i class="fas fa-share"></i> Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </section>
</template>

<script>
import appNavigation from '../components/Navigation'
import appFooter from '../components/Footer.vue'

export default {
  components: {
    appNavigation,
    appFooter
  },
  head: {
    title: "Get in touch | DevOps Engineer, AWS Engineer, Software Developer, VueJS Developer, NodeJS Developer, PHP Developer, and more | Adam L Miller",
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'I am a seasoned veteran in AWS DevOps as well as Software Development using VueJS, NodeJS, PHP and more!'
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'devops engineer, aws engineer, software developer, vuejs developer, nodejs developer, php developer'
      }
    ]
  }
}
</script>